<template>
	<div class="cont-wrap hide-form">
		<div class="cont-txt-box"></div>
		<div
			class="form-wrap"
			id="contform"
		></div>
	</div>
</template>

<script>
import axios from 'axios'
export default {
	name: 'HsForm',
	props: {
		value: Object,
		region: String,
		portalId: String,
		formId: String,
	},
	watch: {
		value: {
			handler(val) {
				if (val) {
					const inputs = document.querySelectorAll("#contform input, #contform textarea");
					inputs.forEach((input) => {
						if (input.type === "text" || input.type === "email" || input.type === "tel" || input.tagName === "TEXTAREA") {
							input.value = val[input.name];
						} else if (input.type === "checkbox" && Array.isArray(val[input.name])) {
							input.checked = val[input.name].includes(input.value);
						} else if (input.type === "file" && val[input.name].length) {
							const dataTransfer = new DataTransfer();
							for (const file of val[input.name]) {
								dataTransfer.items.add(file);
							}
							input.files = dataTransfer.files;
						}
					});
				}
			},
			deep: true,
		},
	},
	async mounted() {
		await this.appendHSFormScript();
		await this.createHubspotForm();
		setTimeout(() => {
			this.parseFormToJson()
			this.$emit('submitFunc', this.submitFunc)
		}, 2500)
	},
	methods: {
		appendHSFormScript() {
			return new Promise((resolve) => {
				const region = this.region ? `-${this.region}` : ''
				const url = `//js${region}.hsforms.net/forms/embed/v2.js`
				const scriptExists = document.querySelector(`script[src="${url}"]`)
				if (scriptExists) {
					resolve()
					return;
				}
				const scriptEl = document.createElement('script')
				scriptEl.setAttribute("src", url);
				scriptEl.setAttribute("type", "text/javascript");
				scriptEl.setAttribute("async", false);

				document.body.appendChild(scriptEl);
				scriptEl.addEventListener("load", () => {
					function resolver() {
						if (!window.hbspt) setTimeout(() => resolver(), 100)
						else resolve()
					}
					resolver()
				})

			})
		},
		parseFormToJson() {
			console.log('parseFormToJson')
			const el = document.querySelector("#contform form");
			const inputs = el.querySelectorAll("input, textarea, select");
			const form = []
			for (const input of inputs) {
				const obj = {};
				obj.name = input.name || input.tagName;
				obj.type = input.type || input.tagName
				if (obj.type === 'SELECT') obj.options = input.querySelectorAll('option').map(option => ({ value: option.value, label: option.innerText }))
				form.push(obj)
			}
			this.$emit('formJson', form)
		},
		submitFunc() {
			return new Promise((resolve, reject) => {
				const form = document.querySelector("#contform form");
				axios.post(form.action, new FormData(form), { headers: { 'Content-Type': form.enctype } }).then((res) => {
					const response = this.parseResponse(res.data)
					resolve(response)
				}).catch((err) => {
					if (!(err?.data || err.response?.data)) reject()
					const response = this.parseResponse(err?.data || err.response?.data)
					reject(response.validationResults.map(e => `${e.fieldName.replace(/_/g, ' ')}: ${e.localizedVisitorErrorMessage}`).join("<br>"))
				})
			})
		},

		parseResponse(str) {
			console.log(str)
			if (!str) return '{}'
			const jsonStartIndex = str.indexOf("{");
			const jsonEndIndex = str.lastIndexOf("}");
			let jsonString = str.slice(jsonStartIndex, jsonEndIndex + 1);
			jsonString = jsonString.replace(/\\x3C/g, '<').replace(/\\x3E/g, '>');
			const jsonResponse = JSON.parse(jsonString)
			return jsonResponse
		},
		createHubspotForm() {
			return window.hbspt.forms.create({
				region: this.region,
				portalId: this.portalId,
				formId: this.formId,
				target: "#contform",
			});
		},
	},
};
</script>


<style scoped>
.hide-form {
	opacity: 0;
	height: 0;
	z-index: -10;
	pointer-events: none;
	overflow: hidden;
}
</style>